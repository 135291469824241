// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import MKButton from 'DS/components/MKButton';

// Icons
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function StartUpBlogCard({ image, title, logo, description, fact, action }) {
  const cardActionStyles = {
    display: 'flex',
    alignItems: 'center',

    '& .material-icons, .material-icons-round,': {
      transform: `translateX(2px)`,
      transition: 'transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)',
    },
  };

  const BoxLogo = {
    display: 'flex',
    background: '#FFF',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };

  return (
    <MKBox style={{}} sx={{ ...BoxLogo }}>
      <MKBox p={3} display="flex" flexDirection="column" justifyContent="space-between" flex="1">
        <MKBox pt="3" height="5rem" textAlign="center">
          <MKBox
            className="img-card"
            component="img"
            src={logo}
            alt={title}
            width="85px"
            position="relative"
            zIndex={1}
            top="auto"
          />
        </MKBox>
        <MKBox py={3} flex="1" style={{ alignSelf: 'flex-start' }}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
          {!!fact && (
            <MKTypography
              variant="body2"
              component="p"
              color="5A5EA6"
              style={{ fontWeight: 'bold' }}
            >
              {fact}
            </MKTypography>
          )}
        </MKBox>

        {action.type === 'external' ? (
          <MKBox display="flex" justifyContent="space-between">
            {/* <MKButton
              type="submit"
              color={'primary'}
              size="medium"
              circular
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              sx={cardActionStyles}
              onClick={() => {
                window.location = action.route;
              }}
            >
              {action.label}
              <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
            </MKButton> */}
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              color={'dark'}
              sx={cardActionStyles}
            >
              {action.label}
              <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
            </MKTypography>
            <MKBox display="flex" justifyContent="space-around" minWidth="40%">
              <MKTypography
                component={MuiLink}
                href={action.lnRoute}
                target="_blank"
                rel="noreferrer"
                variant="body1"
                fontWeight="regular"
                color={'dark'}
                sx={cardActionStyles}
              >
                {action.lnRoute ? <LinkedInIcon fontSize="medium" /> : null}
              </MKTypography>
              <MKTypography
                component={MuiLink}
                href={action.twRoute}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                fontWeight="regular"
                color={'dark'}
                sx={cardActionStyles}
              >
                {action.twRoute ? <TwitterIcon fontSize="medium" /> : null}
              </MKTypography>
              <MKTypography
                component={MuiLink}
                href={action.igRoute}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                fontWeight="regular"
                color={'dark'}
                sx={cardActionStyles}
              >
                {action.igRoute ? <InstagramIcon fontSize="medium" /> : null}
              </MKTypography>
            </MKBox>
          </MKBox>
        ) : (
          <MKTypography
            component={Link}
            to={action.route}
            variant="body2"
            fontWeight="regular"
            color={'dark'}
            sx={cardActionStyles}
          >
            {action.label}
            <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
          </MKTypography>
        )}
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the RaisedBlogCard
StartUpBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};
// image, title, logo, description, fact, action
export default StartUpBlogCard;
