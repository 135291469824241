import { useRef } from 'react';

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper';

// SwiperJS react components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

import Grid from '@mui/material/Grid';
import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import TestimonialCard from 'DS/complex/Cards/InfoCards/TestimonialCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Logos
import { useRouter } from 'helpers/useRouter';

function Testimonials() {
  const router = useRouter();

  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '100%',
    textAlign: 'center',
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    '&:hover, &:focus': {
      opacity: 1,
    },
    '&.swiper-button-disabled img': {
      width: '42px',
      opacity: 0.5,
    },
  };

  const CardsStyles = {
    overflow: 'hidden',
    width: '440px',
    height: 'auto',
    padding: '15px',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  };

  return (
    <MKBox
      sx={{
        pb: { xs: 1, sm: 1, md: 5 },
      }}
      position="relative"
      alignItems="center"
    >
      <CustomContainer>
        <MKBox pb={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }}>
                · Testimonials
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12} a flexDirection="column">
              <MKTypography variant="h4" color="text">
                <b>What</b> they say
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox>
          <Swiper slidesPerView={'auto'} spaceBetween={5}>
            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Constanza Naso"
                subtitle="CEO & Co Founder @ MAIA"
                quote="A space to grow shoulder to shoulder with incredible people with a lot of experience and generosity, undoubtedly highly recommended."
                direction="center"
                color="primary"
                img={
                  'https://media.licdn.com/dms/image/D4D03AQE4ln1SA2wuJw/profile-displayphoto-shrink_100_100/0/1699708861619?e=1717632000&v=beta&t=ClwRidQ9j1cLeXFZ0rl4820c5G2VVMHLyI41e6Bj3ao'
                }
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/constanza-naso-66248462?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAA09dqoBEtpqQB8fzOBSIXue7CCDEFl67AA&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BEQWN8tZsSreU%2BT7wG9r5Vg%3D%3D'
                  );
                }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Yamila Palloni"
                subtitle="CEO & Co Founder @ Somos FIT"
                quote="Koi is the secret sauce that any startup would want to have."
                direction="center"
                color="primary"
                img={
                  'https://media.licdn.com/dms/image/C4D03AQG0kzrBXrfN0Q/profile-displayphoto-shrink_100_100/0/1659980959138?e=1717632000&v=beta&t=BwpTs1SbAfl20QunelxLCUw9N-FoCwxOgRj_5rrJ-9I'
                }
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/yamila-palloni-91397278?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABCENZ8B5Oprww5QRlCkwqBg06eImruTuMA&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BQkbYTHjdTbKBY5n%2BW6r%2BPA%3D%3D'
                  );
                }}
              />
            </SwiperSlide>
            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Santiago Lacasia"
                subtitle="CEO & Co Founder @ Aconcagua"
                quote="A team that gives 100% to help us reach the top."
                img={
                  'https://media.licdn.com/dms/image/C4D03AQGZLC6NFq2j9g/profile-displayphoto-shrink_100_100/0/1627062720066?e=1717632000&v=beta&t=NjxdkgGcAmGEtw87AZW5MnLdeBNUX002mVB5cIurxKg'
                }
                direction="center"
                color="secondary"
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/santiago-lacasia?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAQ9UNEBfZKgp5EYnRHs-8cm0HXwgIM-vZE&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bn11ay9TiSTSVJ%2Fy98PW1Rg%3D%3D'
                  );
                }}
              />
            </SwiperSlide>

            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Candelaria Sosa"
                subtitle="Co Founder @ Tributo Simple"
                quote="A home away from home, the best place to start your dream."
                img={
                  'https://media.licdn.com/dms/image/C4D03AQFvI8Odfk38AA/profile-displayphoto-shrink_100_100/0/1660831412141?e=1717632000&v=beta&t=ElDV2wMFD4WO7RL9Eyi-voVncAxKRSlupGloV-_Stvo'
                }
                direction="center"
                color="primary"
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/mariacandelariasosa?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAMN_q0BFG-knp7bxWWaHP1c9C897x5f_dU&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BqUZ6GmkYSjSEEmCVGhAaOg%3D%3D'
                  );
                }}
              />
            </SwiperSlide>
          </Swiper>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Testimonials;
