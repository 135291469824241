import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images

import miche from 'assets/images/team/miche.png';
import mili from 'assets/images/team/mili.png';
import pedro from 'assets/images/team/pedro.png';
import marlu from 'assets/images/team/marlu.png';
import mica from 'assets/images/team/mica.png';
import blas from 'assets/images/team/blas.png';
import gaston from 'assets/images/team/gaston.png';
import pato from 'assets/images/team/pato.png';
import gasti from 'assets/images/team/gasti.jpeg';
import mati from 'assets/images/team/mati.jpeg';

import fishUp from 'assets/images/fish_up.png';
import fishDown from 'assets/images/fish_down.png';

function Team() {
  const data = [
    {
      name: 'Michel',
      description: 'Co-Founder & CTO',
      image: miche,
    },
    {
      name: 'Mili',
      description: 'Co-Founder & CEO',
      image: mili,
    },

    {
      name: 'Javier (Pato)',
      description: 'Operating Partner',
      image: pato,
    },

    {
      name: 'Gastón',
      description: 'Product Owner',
      image: gaston,
    },
    {
      name: 'Blas',
      description: 'Investment Analyst',
      image: blas,
    },
    // {
    //   name: 'Micaela',
    //   description: 'Head of Marketing',
    //   image: mica,
    // },
    // {
    //   name: 'Marlu',
    //   description: 'Program & Portfolio Manager',
    //   image: marlu,
    // },
    // {
    //   name: 'Pedro',
    //   description: 'Finance Analyst',
    //   image: pedro,
    // },
    {
      name: 'Gastón',
      description: 'Portfolio manager',
      image: gasti,
    },
    {
      name: 'Matias',
      description: 'Product Analyst',
      image: mati,
    },
  ];

  const slidesMap = [
    {
      speed: 1.2,
      images: data,
    },
    {
      speed: -0.8,
      images: data,
    },
    {
      speed: 0.3,
      images: data,
    },
    {
      speed: -0.5,
      images: data,
    },
    {
      speed: 1,
      images: data,
    },
    {
      speed: -1.3,
      images: data,
    },
  ];

  const SlideStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '16px',
    py: '24px',
    overflow: 'hidden',
    '.splide__track': {
      overflow: 'hidden',
      position: 'relative',
      zIndex: '0',
      padding: '6px',
    },
  };

  const SliderProps = {
    type: 'loop',
    gap: '10px',
    drag: 'free',
    arrows: false,
    pagination: false,
    perPage: 3,
    direction: 'ttb',
    height: '500px',
  };

  const Description = {
    position: 'absolute',
    background: '#07092B',
    bottom: '100%',
    padding: '14px 15px',
    width: '100%',
  };

  const Card = {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    width: '220px',
    height: '385px',
    opacity: '.6',
    borderRadius: '16px',
    '&:hover': {
      opacity: '1',
      transition: 'all 150ms ease-in',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
      '.img-card': {},
      '.description': {
        bottom: '0',
        zIndex: '1',
      },
    },
  };

  const FishDown = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: '-24px',
      right: '85%',
      width: '54px',
      height: '136px',
      zIndex: '1',
    },
  };

  const FishUp = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishUp})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '-24px',
      right: '85%',
      width: '54px',
      height: '136px',
      zIndex: '1',
    },
  };

  return (
    <MKBox
      display="flex"
      sx={{
        pt: { xs: 1, sm: 1, md: 6 },
      }}
    >
      <CustomContainer>
        <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="flywheel" color="primary">
                · TEAM
              </MKTypography>
              <MKTypography variant="h1" color="text" mb={1}>
                <b>Who</b> we are
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Meet Team KOI, a collective of passionate innovators and seasoned industry experts.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          sx={{
            ...SlideStyle,
          }}
        >
          {slidesMap &&
            slidesMap.map((slidesItem, key) => (
              <MKBox
                sx={key === 1 ? { ...FishDown } : key === slidesMap.length - 1 && { ...FishUp }}
              >
                <Splide
                  options={{
                    ...SliderProps,
                    autoScroll: {
                      pauseOnHover: true,
                      pauseOnFocus: true,
                      rewind: false,
                      speed: slidesItem.speed,
                    },
                  }}
                  extensions={{ AutoScroll }}
                >
                  {slidesItem.images &&
                    slidesItem.images.map((item) => (
                      <SplideSlide>
                        <MKBox
                          sx={{
                            ...Card,
                          }}
                        >
                          <MKBox
                            className="img-card"
                            component="img"
                            src={item.image}
                            alt={item.name}
                            width="100%"
                            position="relative"
                            zIndex={1}
                            top="auto"
                          />
                          <MKBox
                            className="description"
                            sx={{
                              ...Description,
                            }}
                          >
                            <MKTypography variant="teamname" component="p" color="white">
                              {item.name}
                            </MKTypography>
                            <MKTypography variant="teampos" component="p" color="white">
                              {item.description}
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </SplideSlide>
                    ))}
                </Splide>
              </MKBox>
            ))}
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Team;
