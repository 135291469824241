import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import { analyticsTag } from 'helpers/coreHelper';
import MKButton from 'DS/components/MKButton';

// Images Header
import headerImg from 'assets/images/img-header.png';
import fishDown from 'assets/images/fish_down.png';
import fishUp from 'assets/images/fish_up.png';
import rocket from 'assets/images/logos/Rocket.png';

// Images Avatars

function Banner({}) {
  const buttonStyle = {
    position: 'relative',

    '&:before': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: { sx: '0%', lg: '-100%' },
      left: { sx: '0%', lg: '500%' },
      width: '54px',
      height: '136px',
      zIndex: '-1',
      transform: 'rotate(240deg)',
    },
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
        id="Growth"
      >
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false} id="Growth">
          <Grid container spacing={1} sx={{ mt: { xs: '20px', sm: '60px' }, px: { xs: 1, lg: 0 } }}>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                mx: 5,
              }}
            >
              <Grid item xs={12} lg={8} sx={{ textAlign: 'left', marginBottom: { xs: '20px' } }}>
                <MKTypography variant="h1" color="primary" fontWeight="bold" mb={5}>
                  Growth
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container xs={12} lg={12}>
              <Grid xs={12} lg={12} sx={{ textAlign: 'left', mb: 2, mx: 5 }}>
                <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }} mt={5}>
                  WHAT WE DO
                </MKTypography>
                <MKTypography variant="h2" fontWeight="light">
                  We offer a 14 week, remote program.
                  <br /> <br />
                  It's designed for impact startups with established recurring revenue streams
                  pursuing international expansion who aspire to raise a Seed or Series A round.
                </MKTypography>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ textAlign: { xs: 'center', lg: 'start' } }}>
              <MKBox m={5}>
                <MKButton
                  color="secondary"
                  size="large"
                  sx={buttonStyle}
                  onClick={() => {
                    analyticsTag({
                      event: 'purchase',
                    });
                    window.open(
                      'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                      '_blank'
                    );
                  }}
                >
                  Apply Now
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Banner;
