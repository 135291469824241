import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import CustomContainer from 'DS/components/Container';
import ColoredBackgroundCard from 'DS/complex/Cards/BackgroundCards/ColoredBackgroundCard';
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';
import { analyticsTag } from 'helpers/coreHelper';

import fishUp from 'assets/images/fish_up.png';
import fishDown from 'assets/images/fish_down.png';
import rocket from 'assets/images/logos/Rocket.png';
import aceleratorIcon from 'assets/images/acelerator-icon.png';
import acceleration from 'assets/images/banners/acceleration-banner.png';
import incubation from 'assets/images/banners/incubation-banner.png';
import bootcampIcon from 'assets/images/bootcamp-icon.png';
import growth from 'assets/images/growth-banner.png';
import incubatorIcon from 'assets/images/incubator-icon.png';

// Hook personalizado para manejar la coincidencia de consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query]);

  return matches;
}

const buttonStyle = {
  position: 'relative',
  '&:before': {
    content: '""',
    backgroundImage: `url(${fishDown})`,
    backgroundRepeat: 'no-repeat',
    display: { xs: 'none', md: 'block' },
    position: 'absolute',
    top: { sx: '0%', lg: '-100%' },
    left: { sx: '0%', lg: '500%' },
    width: '54px',
    height: '136px',
    zIndex: '-1',
    transform: 'rotate(240deg)',
  },
};

function Banner() {
  const isXs = useMediaQuery('(max-width: 720px)');

  // Array para almacenar la información de los programas
  const programsInfo = [
    {
      title: 'Incubation',
      icon: incubatorIcon,
      image: incubation,
      color: 'secondary',
      description: '4 weeks, remote program designed for startups shaping their MVP.',
      action: {
        type: 'internal',
        route: '/incubation',
        label: 'Explore Incubation',
      },
      event: 'incubation',
    },
    {
      title: 'Acceleration',
      icon: bootcampIcon,
      image: acceleration,
      color: 'primary',
      description:
        '12 weeks, on-site program designed for startups looking to find Product Market Fit.',
      action: {
        type: 'internal',
        route: '/acceleration',
        label: 'Explore Acceleration',
      },
      event: 'acceleration',
    },
    {
      title: 'Growth',
      icon: aceleratorIcon,
      image: growth,
      color: 'warning',
      description:
        '14 weeks, remote program designed for startups looking to scale-up their business.',
      action: {
        type: 'internal',
        route: '/growth',
        label: 'Explore Growth',
      },
      event: 'growth',
    },
  ];

  return (
    <>
      <MKBox width="100%" sx={{ display: 'grid', placeItems: 'center' }}>
        <CustomContainer
          sx={{ maxWidth: '1620px!important', my: { xs: '20px', sm: '60px' } }}
          maxWidth={false}
        >
          {/* Sección de encabezado con descripción */}
          <Grid container spacing={1} sx={{ px: { xs: 1, lg: 0 } }}>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{ display: 'flex', flexDirection: 'row', px: { xs: 0, lg: 2 } }}
            >
              <Grid item xs={12} lg={12} sx={{ textAlign: 'start' }}>
                <MKTypography
                  variant="h1"
                  color="text"
                  fontWeight="light"
                  sx={{ mr: 5, ml: 5, my: { xs: '20px', sm: '60px' } }}
                >
                  We offer a wide variety of <b>programs</b> designed to support and{' '}
                  <b>boost startups</b> at every stage of their journey.
                </MKTypography>
                <MKBox m={5}>
                  <MKButton
                    color="secondary"
                    size="large"
                    sx={buttonStyle}
                    onClick={() => {
                      analyticsTag({ event: 'purchase' });
                      window.open(
                        'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                        '_blank'
                      );
                    }}
                  >
                    Apply Now
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>

          {/* Contenedor de grid para las tarjetas de los programas */}
          <Grid container spacing={1} justifyContent="center" mt={10}>
            {programsInfo.map((program, index) => (
              <Grid
                key={index}
                item
                xs={12}
                lg={4}
                onClick={() => {
                  analyticsTag({ event: program.event });
                  window.location = program.action.route;
                }}
              >
                {/* Cada tarjeta de programa se genera dinámicamente */}
                <ColoredBackgroundCard
                  title={program.title}
                  icon={program.icon}
                  image={program.image}
                  color={program.color}
                  description={program.description}
                  action={program.action}
                  isVisible={isXs}
                />
              </Grid>
            ))}
          </Grid>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Banner;
