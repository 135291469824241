// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
import bgImage from 'assets/images/cta.png';
import { analyticsTag } from 'helpers/coreHelper';

import fishUp from 'assets/images/fish_up.png';
import fishDown from 'assets/images/fish_down.png';

function Cta() {
  const Background = {
    backgroundImage: { xs: 'none', sm: 'none', md: `url(${bgImage})` },
    backgroundSize: '35% 100%',
    backgroundColor: '#FCAE9B',
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
  };

  const fakeButton = {
    background: '#DB6639',
    border: '2px solid #07092B',
    boxShadow: '-6px 6px 0px 0px #07092B',
    marginTop: '-6px',
    marginLeft: '6px',
    marginRight: '-6px',
    textTransform: 'uppercase',
    padding: '8px 16px',
  };

  const buttonStyle = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '85%',
      left: '20%',
      width: '54px',
      height: '136px',
      zIndex: '1',
      transform: 'rotate(60deg)',
    },
    '&:before': {
      content: '""',
      backgroundImage: `url(${fishUp})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: '85%',
      left: '80%',
      width: '54px',
      height: '136px',
      zIndex: '-1',
      transform: 'rotate(60deg)',
    },
  };

  return (
    <MKBox
      display="flex"
      py={7}
      position="relative"
      sx={{
        ...Background,
      }}
    >
      <CustomContainer>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          // textAlign="center"
          sx={{
            gap: { xs: '12px', sm: '24px', md: '64px' },
            paddingLeft: { sm: 0, md: '40%' },
            justifyContent: { xs: 'center', md: 'start' },
          }}
        >
          <MKTypography
            variant="h6"
            color="dark"
            fontWeight="bold"
            display="inline-flex"
            gap="16px"
            alignItems="center"
          >
            Ready to{' '}
            <MKButton
              color="secondary"
              size="large"
              sx={{ boxShadow: 'none', padding: '0 3em', ...buttonStyle }}
              onClick={() => {
                analyticsTag({
                  event: 'purchase',
                });
                window.location = 'https://airtable.com/appjONwgEhlXaWksq/shrk0YsuSgSkB6b2D';
              }}
            >
              scale up
            </MKButton>{' '}
            your startup?
          </MKTypography>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Cta;
