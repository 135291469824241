import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, LinearProgress } from '@mui/material';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Hook personalizado para consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query]);

  return matches;
}

// Hook personalizado para un contador de números
function useNumberCounter(end, duration = 4000, incrementTime = 100) {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    let start = 0;
    const step = (end - start) / (duration / incrementTime);
    const interval = setInterval(() => {
      start += step;
      if (start >= end) {
        start = end;
        clearInterval(interval);
      }
      setNumber(Math.round(start));
    }, incrementTime);

    return () => clearInterval(interval);
  }, [end, duration, incrementTime]);

  return number;
}

function InfoBanner() {
  // Verifica si el tamaño de la pantalla es menor a 720px
  const isXs = useMediaQuery('(max-width: 720px)');

  // Datos para los elementos del grid
  const gridData = [
    { label: 'Programs', end: 6, color: '#DB6639' },
    { label: 'Startups', end: 44, color: '#DB6639' },
    { label: 'Female Founders', end: 51, color: '#DB6639' },
  ];

  return (
    <MKBox
      py={{
        xs: 5,
        sm: 10,
      }}
      width="100%"
      sx={{ display: 'grid', placeItems: 'center' }}
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            px: { xs: 0, lg: 2 },
            justifyContent: 'center',
            mt: { xs: '20px', sm: '60px' },
          }}
        >
          <Grid item xs={12} lg={12} sx={{ textAlign: 'center' }}>
            <MKTypography variant="flywheel" color="secondary" sx={{ fontWeight: 300 }}>
              OUR TRACK RECORD
            </MKTypography>
            <MKTypography variant="h4" color="text" fontWeight="bold">
              What we’ve achieved
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            mt: { xs: '20px', sm: '60px' },
            px: { xs: 1, lg: 0 },
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          {/* Mapea los datos del grid para crear cada elemento dinámicamente */}
          {gridData.map((item, index) => {
            // Usa el hook personalizado para el contador de números
            const count = useNumberCounter(
              typeof item.end === 'number' ? item.end : parseFloat(item.end)
            );
            return (
              <Grid
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: '33%' },
                  mt: { xs: 10, sm: 0 },
                }}
              >
                <Box
                  sx={{
                    width: 'fit-content',
                    padding: '20px 30px',
                    borderRadius: '20px',
                    transform: 'all 150ms ease-in',
                  }}
                >
                  <MKTypography
                    variant="h1"
                    align="center"
                    fontWeight="bold"
                    sx={{ color: item.color, fontSize: 60 }}
                  >
                    {item.label === 'Female Founders' ? `${count}%` : count}
                  </MKTypography>
                  <MKTypography
                    variant="h5"
                    align="center"
                    fontWeight="bold"
                    sx={{ mt: '20px', color: '#000' }}
                  >
                    {item.label}
                  </MKTypography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default InfoBanner;
