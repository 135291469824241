import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import AboutUs from './sections/AboutUs';
import Banner from './sections/Banner';
import Cta from './sections/CallToAction';
import ContactUs from './sections/ContactUs';
import Portfolio from './sections/Portfolio';
import Companies from './sections/Companies';
import Testimonials from './sections/Testimonials';
import Vision from './sections/Vision';

import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Header from 'pages/shared/components/Header';
import Team from './sections/Team';

function Landing() {
  useEffect(() => {
    document.title = 'Koi Ventures';
  }, []);

  return (
    <>
      <Header transparent light relative />
      <Banner />
      <AboutUs />
      <Vision />
      <Companies />
      {/* <Portfolio /> */}
      <Team />
      {/* <Testimonials /> */}
      {/* <Cta /> */}
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Landing;
