/* eslint-disable no-console */
import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import Container from '@mui/material/Container';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

import Header from 'pages/shared/components/Header';

import MKBox from 'DS/components/MKBox';

// HelpCenter page components
import { useRouter } from 'helpers/useRouter';

import { analyticsTag } from 'helpers/coreHelper';

import Grid from '@mui/material/Grid';
import MKButton from 'DS/components/MKButton';
import MKInput from 'DS/components/MKInput';
import MKTypography from 'DS/components/MKTypography';
import { useState } from 'react';

import { createAirtableRegistry } from 'services/airtableServices';
import { BrandingWatermarkOutlined } from '@mui/icons-material';

function PrivacyPolicy() {
  const router = useRouter();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    document.title = 'Koi Ventures - VC';
  }, []);

  const handleSubmit = async () => {
    try {
      analyticsTag({
        event: 'purchase',
      });

      const data = { name, email, phoneNumber, searchString: window.location.search };

      const recordsData = {
        records: [
          {
            fields: data,
          },
        ],
      };
      await createAirtableRegistry('Leads', recordsData);

      router.push('/sucesss');
    } catch (e) {
      console.log('error: ', e);
      alert('Ups, an error occur');
    }
  };
  return (
    <>
      <Header transparent light relative />
      <Container>
        <Grid spacing={3} sx={{ mb: 4, mt: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
            <MKTypography variant="h3" color="primary" mb={6}>
              PERSONAL DATA PRIVACY POLICIES FOR USERS OF THE KOI VENTURES WEBSITE
            </MKTypography>
            <MKTypography variant="subtitle2" color="grey" style={{ textAlign: 'justify' }}>
              These Personal Data Privacy Policies (hereinafter the “PdP”) apply those users who use
              the following web platform:{' '}
              <a href="https://koigroup.io/Home" target="blank">
                https://koigroup.io/Home
              </a>{' '}
              (hereinafter “The Platform”), which is owned by of KOI VENTURES (hereinafter “KOI”).
              At KOI the security of personal information that we collect is of utmost importance to
              us. You can find information about how we handle and manage your personal information
              in this Privacy Policy. Our aim is to provide this information to you in an open and
              transparent manner, so that you can make informed decisions about your dealings with
              us. However, if you have any questions about this Privacy Policy or how we manage your
              personal information, please contact us here info@koigroup.io. In that same email you
              can contact us for the correction of the personal information that we hold about you.
              We may update or revise this Privacy Policy from time to time. You can find the most
              current version on our website or ask as for a copy in the detailed email.
            </MKTypography>
            <MKTypography variant="h4" color="primary" m={4}>
              ABOUT THIS PRIVACY POLICY
            </MKTypography>
            <MKTypography
              variant="subtitle2"
              color="grey"
              style={{ marginTop: 20, textAlign: 'justify' }}
            >
              KOI uses the most advanced technology to protect the information provided by Users of
              the Services. The only purpose of the use of the Personal Data Registered on the
              Website is to validate users identity and improve the information and marketing of the
              products and services provided. Users data will not be transferred to third parties,
              except people or companies related to KOI. All security measures have been implemented
              to protect the personal data required of Users who navigate the Website. Databases may
              be generated with the information provided by the User, which will be used by KOI
              exclusively for commercial purposes. All information requested from the User must be
              provided by the User voluntarily.
            </MKTypography>
            <MKTypography variant="h4" color="primary" m={4}>
              COOKIES
            </MKTypography>
            <MKTypography variant="subtitle2" color="grey" style={{ textAlign: 'justify' }}>
              For each visitor to our website, our server automatically recognises and stores the
              visitor's IP address. In addition, our website use cookies. To enhance your experience
              when using our website, these store information you have provided to us (such as your
              postcode, preferences, region and sign in username) to enable us to provide a more
              personalised service to you. Sometimes our website contains links to third party
              websites, for your convenience and information. When you access a non-KOI website,
              please understand that KOI is not responsible for the privacy practices of that site.
              We suggest that you review the privacy policies of each site you visit.
            </MKTypography>
            <MKTypography variant="h4" color="primary" m={4}>
              WHAT ARE YOUR RIGHTS AS A DATA USER
            </MKTypography>
            <MKTypography variant="subtitle2" color="grey" style={{ textAlign: 'justify' }}>
              At KOI, your privacy is one of our greatest concerns. We will try to be as transparent
              as possible in the processing of your personal data and that is why, from the moment
              you trust us with your information, you will have the following rights: <br></br>-
              ACCESS. You will have the right to request access to your personal data, as well as to
              know any information related to the conditions of your treatment.<br></br> -
              RECTIFICATION. You will have the right to request rectification of your personal data
              when it turns out to be inaccurate, incomplete or not updated.<br></br> -
              CANCELLATION. You will have the right to request the deletion of your personal data,
              so that they are not processed by KOI, unless it could cause damage to the rights or
              legitimate interests of KOI or third parties, or when there is a legal obligation to
              preserve them.<br></br> - OPPOSITION. You will have the right to oppose the processing
              of your data, or against a specific purpose, when you have not given consent and/or
              your processing is not legitimate or justified in accordance with current regulations.
              To exercise any of these rights, and even to exercise any other that you consider
              linked to them, you can write to us at info@koigroup.io and we will immediately
              process your request and inform you of the results within the period provided for by
              current law.
            </MKTypography>
            <MKTypography variant="h4" color="primary" m={4}>
              HOW LONG WE STORE YOUR PERSONAL DATA
            </MKTypography>
            <MKTypography variant="subtitle2" color="grey" style={{ textAlign: 'justify' }}>
              KOI will retain your personal data as long as it is necessary to achieve the purposes
              for which it was collected, to comply with regulatory or legal requirements, or during
              the prescription period of possible legal or contractual liabilities, or could cause
              damage to the rights or legitimate interests of KOI. or third parties. Please note
              that there are regulations that require us to retain certain information for a special
              period of time, which could be 2, 5 and up to 10 years. To determine the appropriate
              retention period for each category of personal data, we consider the amount, nature
              and sensitivity of the data, as well as the potential risk (arising from unauthorized
              use and/or unlawful disclosure), the purposes of the treatment and whether it is
              possible to fulfill said purposes by other means in accordance with current law.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default PrivacyPolicy;
