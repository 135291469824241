/**
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// Pages

import Home from 'pages/home';
import VenturesLanding from 'pages/venturesLanding';
// import TechnologyLanding from 'pages/technologyLanding';
// import FoundationLanding from 'pages/foundationLanding';
import PortfolioLanding from 'pages/portfolioLanding';
import AcademyLanding from 'pages/academyLanding';
import ContactSuccess from 'pages/contactSuccess';
import AboutUsLanding from 'pages/aboutUs';
import AcademyLandingIncubation from 'pages/incubation/sections/Incubation';
import AcademyLandingAcceleration from 'pages/acceleration/sections/Acceleration';
import AcademyLandingGrowth from 'pages/growth/sections/Growth';
import PrivacyPolicy from 'pages/privacyPolicy';

const routes = [
  {
    name: 'Home',
    key: 'home',
    hideMenu: false,
    route: '/Home',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: Home,
  },
  {
    name: 'About Us',
    key: 'aboutUs',
    hideMenu: false,
    route: '/AboutUs',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: AboutUsLanding,
  },
  {
    name: 'Ventures',
    key: 'ventures',
    hideMenu: true,
    route: '/ventures',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: VenturesLanding,
  },
  /* {
    name: 'Technology',
    key: 'technology',
    hideMenu: false,
    route: '/technology',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: TechnologyLanding,
  },
  {
    name: 'Foundation',
    key: 'foundation',
    hideMenu: false,
    route: '/foundation',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: FoundationLanding,
  },*/
  {
    name: 'Portfolio',
    key: 'portfolio',
    hideMenu: false,
    route: '/portfolio',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: PortfolioLanding,
  },
  {
    name: 'Academy',
    key: 'academy',
    hideMenu: false,
    route: '/academy',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: AcademyLanding,
  },
  {
    name: 'Incubation',
    key: 'Incubation',
    hideMenu: false,
    route: '/incubation',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: AcademyLandingIncubation,
  },
  {
    name: 'Acceleration',
    key: 'Acceleration',
    hideMenu: false,
    route: '/acceleration',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: AcademyLandingAcceleration,
  },
  {
    name: 'Growth',
    key: 'Growth',
    hideMenu: false,
    route: '/growth',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: AcademyLandingGrowth,
  },
  {
    name: 'Privacy Policy',
    key: 'PrivacyPolicy',
    hideMenu: false,
    route: '/privacy-policy',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: PrivacyPolicy,
  },

  {
    name: 'Success',
    key: 'sucesss',
    hideMenu: false,
    route: '/sucesss',
    targetLayout: ['landing', 'auth-flow', 'anonymous'],
    component: ContactSuccess,
  },
];

export default routes;
