import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// SwiperJS

import CustomContainer from 'DS/components/Container';

// SwiperJS react components

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Material Kit 2 PRO React components
import ColoredBackgroundCard from 'DS/complex/Cards/BackgroundCards/ColoredBackgroundCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images
import bootcampIcon from 'assets/images/bootcamp-icon.png';
import incubatorIcon from 'assets/images/incubator-icon.png';
import academy from 'assets/images/academy-banner.png';
import ventures from 'assets/images/ventures-banner.png';

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function AboutUs() {
  const isXs = useMediaQuery('(max-width: 720px)');
  return (
    <MKBox component="section" pb={12} px={5}>
      <CustomContainer>
        <MKBox pt={6} pb={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={7} a flexDirection="column">
              <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }}>
                · ABOUT US
              </MKTypography>
              <MKTypography variant="h1" color="text">
                <b>What</b> we do
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <Grid container spacing={1} justifyContent="center" gap={3}>
          <Grid
            item
            xs={12}
            lg={4}
            onClick={() => {
              // link to bootcamp page
              window.location = '/academy';
            }}
          >
            <ColoredBackgroundCard
              title={'Academy'}
              icon={bootcampIcon}
              image={academy}
              color="primary"
              description="Impact-driven incubation, acceleration and growth programs for startups."
              action={{
                type: 'internal',
                route: '/academy',
                label: 'Explore Academy',
              }}
              isVisible={isXs}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            onClick={() => {
              // link to ventures page
              window.location = '/ventures';
            }}
          >
            <ColoredBackgroundCard
              title="Ventures"
              image={ventures}
              icon={incubatorIcon}
              color="secondary"
              description="We offer innovative investment opportunities to the ecosystem."
              isVisible={isXs}
              action={{
                type: 'internal',
                route: '/ventures',
                label: 'Explore Ventures',
              }}
            />
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default AboutUs;
