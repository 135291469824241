import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Header from 'pages/shared/components/Header';
import AboutUs from './sections/AboutUs';
import Banner from './sections/Banner';
import InfoBanner from './sections/Info';
import Process from './sections/Process';
import CtaAcademy from './sections/CallToActionAcademy';
import Testimonials from './sections/Testimonials';
import ContactUs from '../home/sections/ContactUs';
import FAQ from './sections/FAQ';

function AcademyLanding() {
  useEffect(() => {
    document.title = 'Koi Ventures';
  }, []);

  return (
    <>
      <Header transparent light relative />
      <Banner />
      <InfoBanner />
      {/* <Process /> */}
      <CtaAcademy />
      <Testimonials />
      {/* <AboutUs /> */}
      {/* <FAQ /> */}
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default AcademyLanding;
