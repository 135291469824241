// First, ensure you have '@mui/material' installed to use these components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images Avatars
const faqs = [
  {
    question: 'What is a rolling fund?',
    answer: `A rolling fund is a type of venture capital fund structure that allows fund managers to raise and deploy capital on a rolling basis, rather than raising a fixed amount of capital upfront. This means that instead of having a traditional closed-end fund with a fixed term, rolling funds operate on a subscription model, where investors commit to investing a certain amount of capital over a defined period, typically on a quarterly basis.
    In the case of Koi, our rolling fund operates with periods of three months, meaning that investors contribute capital every quarter, and investments are made accordingly. This structure provides flexibility for both fund managers and investors, allowing for a continuous flow of capital into startups throughout the fund's lifecycle. It also enables investors to diversify their investments over time and allows fund managers to adapt to changing market conditions and investment opportunities.`,
  },
  {
    question: 'When is my investment recovered?',
    answer: (
      <>
        <MKTypography variant="text" color="text">
          You will receive a return and recover your investment in any of the following scenarios:
        </MKTypography>
        {/* lista punteada */}
        <ul>
          <li>
            <MKTypography variant="text" color="text">
              Startup Exit: When a startup exits, you will receive your return on investment.
            </MKTypography>
          </li>
          <li>
            <MKTypography variant="text" color="text">
              Startup Dividends: In exceptional cases, some startups may distribute dividends
              instead of having an exit, and in such cases, KOI directly distributes those funds to
              the investors.
            </MKTypography>
          </li>
          <li>
            <MKTypography variant="text" color="text">
              KOI Stake Sale (CASHOUT): If someone acquires KOI’s stake, not necessarily as a
              startup exit, it generates a CASHOUT for the investors.
            </MKTypography>
          </li>
          <li>
            <MKTypography variant="text" color="text">
              Secondary Market: In the event that an LP wishes to perform their own CASHOUT, we
              facilitate communication with other LPs to seek private agreements. Although KOI does
              not directly benefit from this transaction, we always promote the possibility of
              agreements between parties. To date, this scenario has not occurred, but we are open
              to connecting those interested in exploring this option further.
            </MKTypography>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Do you have carry or management fees?',
    answer: `We charge 2% in management fees but 0% carry.`,
  },
  {
    question: 'What do we Consider as Impact?',
    answer: `At Koi Ventures, we assess impact across a range of sectors, with a particular focus on wellness, social, and climate initiatives. Impact is evaluated based on the tangible difference our startups make in the lives of their customers or in addressing societal and environmental challenges. For instance, our startups may contribute to improving well-being, fostering social inclusion, or mitigating climate change. We evaluate the effectiveness of these efforts by assessing the extent to which customers experience meaningful outcomes as a result of the products or services offered.`,
  },
  {
    question: 'How is the startup chosen and its participation in the SPV determined?',
    answer: `The selection of the startup and its participation in the SPV is carried out through our programs, done in our Academy business unit. We use the program as our due diligence process to evaluate and select the best opportunities. Once the programs are completed, the Ventures team selects some or all of the startups that went through the programs and groups them into SPVs.`,
  },
  {
    question: 'What do you mean by "double selection process"?',
    answer: `First, Academy selects startups for its programs. On average, they select 3% of startups analyzed in each program. Once the program finishes, Ventures selects from those startups which will be included in the SPV. This process ensures a rigorous selection based on multiple criteria.`,
  },
  {
    question: 'What is meant by "thorough due diligence"?',
    answer: (
      <>
        <ul>
          <li>
            <MKTypography variant="text" color="text">
              Depending on the program, which typically lasts between 1 and 3 months, we use this
              time to understand details of the startups, their challenges, team dynamics,
              coachability, among others.
            </MKTypography>
          </li>
          <li>
            <MKTypography variant="text" color="text">
              In addition to legal due diligence, we believe that getting to know the
              behind-the-scenes by working closely during the programs allows us to conduct a
              thorough due diligence.
            </MKTypography>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Why is the portfolio diversified?',
    answer: (
      <>
        <ul>
          <li>
            <MKTypography variant="text" color="text">
              Each SPV comprises various startups that meet Academy's parameters, which include
              generating positive impact and having female founders.
            </MKTypography>
          </li>
          <li>
            <MKTypography variant="text" color="text">
              Currently, all SPVs include at least one startup in each Wellness, Social, and
              Environmental vertical, and the average number of startups with female founders is
              66%.
            </MKTypography>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'What is an SPV?',
    answer: `Regarding "SPV," it stands for "Special Purpose Vehicle," a legal entity created for a specific purpose, typically to isolate risk or for financial engineering.`,
  },
];
function FAQ({}) {
  const isXs = useMediaQuery('(max-width: 720px)');

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
          mt: 5,
        }}
      >
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
          <Grid
            item
            xs={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              px: { xs: 0, lg: 2 },
              justifyContent: 'center',
              mt: { xs: '20px', sm: '60px' },
            }}
          >
            <Grid item xs={12} lg={12} sx={{ textAlign: 'left' }}>
              <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }}>
                · HELP
              </MKTypography>
              <MKTypography variant="h4" color="text" fontWeight="bold">
                FAQ
              </MKTypography>
            </Grid>
          </Grid>

          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                width: '100%',
                mt: 2,
                border: 'none',
                backgroundColor: '#FFFEF8',
                boxShadow: 'none',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <MKTypography variant="h6" color="text" fontWeight="bold">
                  {faq.question}
                </MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography variant="text" color="text">
                  {faq.answer}
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          ))}
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default FAQ;
