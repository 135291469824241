import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// SwiperJS
import CustomContainer from 'DS/components/Container';

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Material Kit 2 PRO React components
import ColoredBackgroundCard from 'DS/complex/Cards/BackgroundCards/ColoredBackgroundCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

import { APPLY_BOOTCAMP_URL } from 'helpers/coreHelper';

// Custom hook to handle media query matching
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query]);

  return matches;
}

function AboutUs() {
  // Using custom hook to determine if the viewport width matches the query
  const isXs = useMediaQuery('(max-width: 720px)');

  // Array to store program information
  const programsInfo = [
    {
      title: 'Incubation',
      color: 'primary',
      url: '/incubation',
      Apply: 'See more',
      description: '4 weeks, remote program designed for startups shaping their MVP.',
      action: {
        type: 'external',
        route: APPLY_BOOTCAMP_URL,
        label: 'Explore Bootcamp',
      },
    },
    {
      title: 'Acceleration',
      color: 'primary',
      url: '/acceleration',
      Apply: 'See more',
      description:
        '12 weeks, on-site program designed for startups looking to find Product Market Fit.',
    },
    {
      title: 'Growth',
      color: 'primary',
      url: '/growth',
      Apply: 'See more',
      description:
        '14 weeks, hybrid program designed for startups looking to scale-up their business.',
    },
  ];

  return (
    <MKBox component="section" pt={7} pb={0}>
      <CustomContainer>
        {/* Header section with description */}
        <Grid xs={12} lg={12} sx={{ textAlign: 'center', marginBottom: 7 }}>
          <MKTypography variant="h4" color="text" mb={1}>
            We offer a robust <b>variety of services</b> designed to support and elevate startups at
            every stage of their journey.
          </MKTypography>
        </Grid>
        {/* Grid container for program cards */}
        <Grid container spacing={1} justifyContent="center">
          {programsInfo.map((program, index) => (
            <Grid key={index} item xs={12} lg={4} sx={{ textAlign: 'center' }}>
              {/* Each program card is generated dynamically */}
              <ColoredBackgroundCard
                title={program.title}
                color={program.color}
                url={program.url}
                Apply={program.Apply}
                description={<div>{program.description}</div>}
                action={program.action}
                isVisible={isXs}
              />
            </Grid>
          ))}
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default AboutUs;
