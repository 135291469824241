// @mui material components

import CustomContainer from 'DS/components/Container';
import React, { useEffect, useRef } from 'react';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import ourVision from 'assets/images/ourVision.png';
import zIndex from '@mui/material/styles/zIndex';

// Images

function Vision() {
  const backgroundStyles = {
    background: '#FFFEF8',
    position: 'relative',
    height: '100%',
    boxShadow: 'inset 0 4px 8px rgba(0, 0, 0, 0.1)', // Añadido para la sombra interna
    zIndex: '0',
    '&::before': {
      content: '""',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '0',
      left: '0',
      background: '#DB6639',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
    '&::after': {
      content: '""',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '0',
      right: '0',
      background: '#F9DA90',
      width: '50%',
      height: '100%',
      zIndex: '-1',
    },
  };

  const Title = {
    display: 'flex',
    background: '#5D5DFB',
    position: 'relative',
    flexDirection: 'column',
    height: 'auto',
    width: { xs: '100%', md: 'auto' },
    borderRadius: { xs: '20px 20px 0px 0px', md: '0px 50px 50px 0px' },
    py: '80px',
    px: { xs: '32px', md: '90px' },
  };

  const Description = {
    display: 'flex',
    position: 'relative',
    px: { xs: '16px', md: '100px' },
    alignItems: 'center',
    justifyContent: 'center',
    border: { xs: '0.5px solid grey', md: 'none' },
    borderRadius: { xs: '0px 0px 20px 20px', md: '0px 50px 50px 0px' },
  };

  return (
    <MKBox
      display="flex"
      position="relative"
      sx={{
        ...backgroundStyles,
      }}
    >
      <CustomContainer>
        <MKBox
          flexDirection="row"
          mx={4}
          display="flex"
          alignItems="strech"
          height="100%"
          sx={{
            background: '#FFFEF8',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            gap: { xs: '0px', md: '8px' },
            mx: { xs: '0' },
          }}
        >
          <MKBox
            sx={{
              ...Title,
            }}
          >
            <MKBox sx={{ display: 'flex', alignItems: 'center' }}>
              <MKTypography variant="flywheel" color="white" sx={{ fontWeight: 300 }}>
                OUR VISION
              </MKTypography>
            </MKBox>
            <MKTypography variant="h1" color="white" mb={1} sx={{ textWrap: 'nowrap' }}>
              <b>Why</b> we do it
            </MKTypography>
          </MKBox>
          <MKBox sx={{ ...Description }}>
            <MKTypography
              color="text"
              mb={1}
              sx={{
                fontWeight: 300,
                fontSize: '1.5rem',
                lineHeight: '2.5rem',
              }}
            >
              We envision a better world. A world where individuals are more connected with
              themselves, others, and the world we live in. Our mission is to contribute towards
              this vision and help make it a reality.
            </MKTypography>
          </MKBox>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Vision;
