import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// prop-types is a library for typechecking of props

// Sections
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import ContactUs from '../shared/sections/ContactUs';
import Companies from './sections/Companies';
import PortfolioUs from './sections/Portfolio';

import Header from 'pages/shared/components/Header';

function Portfolio() {
  useEffect(() => {
    document.title = 'Koi Ventures - Portfolio';
  }, []);

  return (
    <>
      <Header transparent light relative />
      <PortfolioUs />
      {/* <Companies /> */}
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Portfolio;
