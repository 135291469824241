import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// SwiperJS

import CustomContainer from 'DS/components/Container';
import crownSVG from 'assets/images/illustrations/crown.svg';
import ticketSVG from 'assets/images/illustrations/ticket.svg';
import arrowSVG from 'assets/images/illustrations/arrow.svg';

// SwiperJS react components

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Material Kit 2 PRO React components
import ColoredBackgroundCard from 'DS/complex/Cards/BackgroundCards/ColoredBackgroundCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images
import image from 'assets/images/thumb-about.png';

import { Box } from '@mui/material';
import { APPLY_ACCELERATOR_URL, APPLY_BOOTCAMP_URL, APPLY_INCUBATOR_URL } from 'helpers/coreHelper';

// Data for the cards
const spvData = [
  { title: 'SPV 1', date: 'JANUARY ‘23', return: '2.17x', value: 'USD 844k' },
  { title: 'SPV 2', date: 'JUNE ‘23', return: '2x', value: 'USD 767k' },
  { title: 'SPV 3', date: 'DECEMBER ‘23', return: '2x', value: 'USD 774k' },
  { title: 'SPV 4', date: 'JUNE ‘24', return: '2x', value: 'USD 195k' },
];

const metricsData = [
  { value: 'USD 3k', label: 'Minimum ticket', color: '#5D5DFB', icon: ticketSVG },
  { value: '5x', label: 'Expected return (5/7 years)', color: '#db6639', icon: crownSVG },
  { value: `2 Q's`, label: 'Minimum', color: '#db6639', icon: arrowSVG },
];

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function AboutUs() {
  const isXs = useMediaQuery('(max-width: 720px)');

  return (
    <MKBox component="section" pt={6} pb={6}>
      <CustomContainer>
        <Grid container spacing={1} justifyContent="center" pb={6}>
          {spvData.map((spv, index) => (
            <Grid item xs={12} lg={2}>
              <ColoredBackgroundCard
                title={spv.title}
                color={
                  index === 0
                    ? 'primary'
                    : index === 1
                    ? 'secondary'
                    : index === 2
                    ? 'terciary'
                    : 'success'
                }
                description={
                  <>
                    <Box sx={{ flexDirection: 'column' }}>
                      <MKTypography>{spv.date}</MKTypography>
                      {spv.title === 'SPV 4' ? (
                        <MKTypography>
                          {spv.return} Moic in less than 2 years. Actual valuation of {spv.value}.
                        </MKTypography>
                      ) : (
                        <MKTypography>
                          {spv.return} return in less than 2 years. Valuation of {spv.value}.
                        </MKTypography>
                      )}
                    </Box>
                  </>
                }
                action={{}}
                isVisible={isXs}
              />
            </Grid>
          ))}
        </Grid>
        <MKBox pt={10} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="space-around" alignItems="start">
            {metricsData.map((metric, index) => (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },

                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: '30%' },
                  mt: { xs: 10, sm: 0 },
                }}
              >
                <img src={metric.icon} alt="icon" />
                <MKTypography variant="h3" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  {metric.value}
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  {metric.label}
                </MKTypography>
              </Grid>
            ))}
          </Grid>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default AboutUs;
