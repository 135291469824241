/* eslint-disable no-console */
import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

import Header from 'pages/shared/components/Header';
import BannerMain from './sections/BannerMain';

// HelpCenter page components
import { useRouter } from 'helpers/useRouter';

import { analyticsTag } from 'helpers/coreHelper';

import { useState } from 'react';

import ContactUs from 'pages/home/sections/ContactUs';
import { createAirtableRegistry } from 'services/airtableServices';
import CardDescription from './sections/CardsDescription';
import Faq from './sections/Faq';
import AboutUs from './sections/AboutUs';

function Landing() {
  const router = useRouter();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    document.title = 'Koi Ventures - VC';
  }, []);

  const handleSubmit = async () => {
    try {
      analyticsTag({
        event: 'purchase',
      });

      const data = { name, email, phoneNumber, searchString: window.location.search };

      const recordsData = {
        records: [
          {
            fields: data,
          },
        ],
      };
      await createAirtableRegistry('Leads', recordsData);

      router.push('/sucesss');
    } catch (e) {
      console.log('error: ', e);
      alert('Ups, an error occur');
    }
  };
  return (
    <>
      <Header transparent light relative />
      <BannerMain />
      <CardDescription />
      <AboutUs />
      <Faq />
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default Landing;
