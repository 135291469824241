import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MKButton from 'DS/components/MKButton';
import Icon from '@mui/material/Icon';
import { analyticsTag } from 'helpers/coreHelper';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import DefaultNavbarDropdown from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarDropdown';
import DefaultNavbarMobile from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarMobile';
import breakpoints from 'assets/theme/base/breakpoints';
import Logo from 'assets/images/Logo.png';

const BRAND_NAME = 'Koi Ventures';

function DefaultNavbar({ transparent, light, action, sticky, relative, center }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const toggleMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    window.addEventListener('resize', displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  const headerRoutes = [
    {
      name: 'Home',
      key: 'home',
      route: '/home',
    },
    {
      name: 'Academy',
      key: 'Academy',
      route: '/academy',
    },
    {
      name: 'Portfolio',
      key: 'portfolio',
      route: '/portfolio',
    },
    {
      name: 'Ventures',
      key: 'ventures',
      route: '/ventures',
    },
    {
      name: (
        <MKButton
          color="secondary"
          size="large"
          onClick={() => {
            analyticsTag({
              event: 'purchase',
            });
            window.open('https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form', '_blank');
          }}
          sx={{ color: 'white' }}
        >
          Apply Now
        </MKButton>
      ),
      key: 'Apply Now',
    },
  ];

  const renderNavbarItemsWithoutApplyNow = headerRoutes.filter(({ key }) => key !== 'Apply Now');

  const renderNavbarItems = renderNavbarItemsWithoutApplyNow.map(
    ({ name, icon, href, route, isActive, subItems }) => {
      return (
        <DefaultNavbarDropdown
          key={name}
          name={name}
          icon={icon}
          href={href}
          route={route}
          light={light}
        />
      );
    }
  );

  return (
    <CustomContainer sx={sticky ? { position: 'sticky', top: 0, zIndex: 10 } : null}>
      <MKBox
        py={2}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        width="100%"
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? 'none' : `saturate(200%) blur(30px)`,
        })}
        borderBottom={'1px solid #07092B'}
      >
        <MKBox
          display="flex"
          justifyContent={{ xs: 'space-between', md: 'space-between' }}
          alignItems="center"
        >
          <MKBox
            component={Link}
            to="/Home"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
            display="flex"
            order={{ xs: 0, sm: 0, md: 0 }}
          >
            <MKBox
              component="img"
              src={Logo}
              alt={BRAND_NAME}
              position="relative"
              zIndex={1}
              sx={{
                height: '4vh',
                maxWidth: '150px',
                minWidth: '50px',
                '@media (max-width: 600px)': {
                  height: '3vh',
                },
              }}
            />
          </MKBox>

          <MKBox
            width="100%"
            display={{ xs: 'none', lg: 'flex' }}
            justifyContent={'flex-end'}
            px={0}
          >
            {renderNavbarItems}
          </MKBox>

          <MKBox
            display={{ xs: 'flex', sm: 'flex', lg: 'none' }}
            lineHeight={0}
            py={1.5}
            pr={2.5}
            color={transparent ? 'primary' : 'inherit'}
            sx={{ cursor: 'pointer' }}
            onClick={toggleMobileNavbar}
            order="1"
          >
            <Icon fontSize="default">{mobileNavbar ? 'close' : 'menu'}</Icon>
          </MKBox>

          <MKBox
            width="15%"
            display={{ xs: 'none', lg: 'flex' }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <MKButton
              color="secondary"
              size="medium"
              onClick={() => {
                analyticsTag({
                  event: 'purchase',
                });
                window.open(
                  'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                  '_blank'
                );
              }}
            >
              Apply Now
            </MKButton>
          </MKBox>
        </MKBox>
        <MKBox
          bgColor={transparent ? 'white' : 'transparent'}
          shadow={transparent ? 'lg' : 'none'}
          borderRadius="xl"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              routes={headerRoutes}
              open={mobileNavbar}
              toggleNavbar={toggleMobileNavbar}
              applyNow={
                <MKButton
                  color="secondary"
                  size="small"
                  fullWidth
                  onClick={() => {
                    analyticsTag({
                      event: 'purchase',
                    });
                    window.open(
                      'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                      '_blank'
                    );
                  }}
                >
                  Apply Now
                </MKButton>
              }
            />
          )}
        </MKBox>
      </MKBox>
    </CustomContainer>
  );
}

DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
