import { useRef, useEffect } from 'react';
import Rellax from 'rellax';

// @mui material components
import Grid from '@mui/material/Grid';

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import StartUpBlogCard from 'DS/complex/Cards/BlogCards/StartUpBlogCard';

import points from 'assets/images/points-services.png';
import { analyticsTag } from 'helpers/coreHelper';
// Services Images
import DrGeaBanner from 'assets/images/banners/banner-drgea.png';
import AconcaguaBanner from 'assets/images/banners/banner-aconcagua.jpg';
import NippyBanner from 'assets/images/banners/banner-nippy.webp';
import SomosFitBanner from 'assets/images/banners/banner-somosfit.jpg';
import MaiaBanner from 'assets/images/banners/banner-maia.png';
import DendroBanner from 'assets/images/banners/banner-dendro.png';
import NoPausaBanner from 'assets/images/banners/banner-no-pausa.png';
import enlite from 'assets/images/logos/Enlite (2).png';

import QxmBanner from 'assets/images/banners/banner-qxm.png';
import ImpulsumBanner from 'assets/images/banners/banner-impulsum.png';

import DrGea from 'assets/images/logos/dr-gea.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import Dendro from 'assets/images/logos/logo-dendro.png';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Enlite from 'assets/images/logos/logo-enlite.jpg';
import Qxm from 'assets/images/logos/logo-qxm.png';
import Impulsum from 'assets/images/logos/logo-impulsum.png';
import Tributo from 'assets/images/logos/logo-tributo.png';
import Busencu from 'assets/images/logos/busencu-logo.svg';
import Duo0 from 'assets/images/logos/duo0-logo.png';
import Glodu from 'assets/images/logos/glodu-logo.png';
import Metamind from 'assets/images/logos/metamind-logo.png';
import Fincrick from 'assets/images/logos/logo-fincrick.png';
import revil from 'assets/images/logos/growth/Logo-revil.png';
import MKButton from 'DS/components/MKButton';
import Link from 'assets/theme/components/link';

function Companies() {
  const ArrowStyles = {
    position: 'relative',
    zIndex: '-9',
  };

  const companies = [
    // Nippy Enlite Reliv Tributo No pausa Akademiapp
    {
      image: revil,
      title: 'Revil',
      logo: revil,
      description:
        'The first digital medical ecosystem in Ecuador, with the aim of putting the patient at the center of attention and making the healthcare process simpler and more agile.',
      action: {
        type: 'external',
        route: 'https://reliv.la',
        label: 'Go to web',
        color: 'primary',
        lnRoute:
          'https://www.linkedin.com/company/reliv-healthcare/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B2rFZ6jxdTLmf0pRPNbi9Ag%3D%3D',
      },
    },

    {
      image: NippyBanner,
      title: 'Nippy',
      logo: Nippy,
      description:
        'Nippy connects the (under-served) gig economy with services, benefits and opportunities.',
      // fact: 'Cool fact: XX% MoM growth',
      action: {
        type: 'external',
        route: 'https://nippy.la/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/nippy-latam/',
        twRoute: 'https://twitter.com/NippyLatam',
      },
    },
    {
      image: Tributo,
      title: 'Tributo Simple',
      logo: Tributo,
      description:
        'Tributo Simple is the first online accountant designed to help independent professionals and small businesses in Latin America.',
      action: {
        type: 'external',
        route: 'https://tributosimple.com/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/tributosimple/',
      },
    },

    {
      image: NoPausaBanner,
      title: 'No Pausa',
      logo: NoPausa,
      description:
        'NoPausa is a community that promotes open dialogue about menopause in addition to Menocheck: its self-assessment and treatment tool for women.',
      action: {
        type: 'external',
        route: 'https://menocheck.no-pausa.com/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/no-pausa-menopausia/',
        igRoute: 'https://www.instagram.com/nopausaig/',
      },
    },
    {
      image: Enlite,
      title: 'Enlite',
      logo: enlite,
      description:
        'Enlite is a platform that optimizes access to a network of therapeutic companions, caregivers, and sociotherapeutic operators.',
      action: {
        type: 'external',
        route: 'https://riat.app/',
        label: 'Go to web',
        color: 'primary',
        lnRoute: 'https://www.linkedin.com/company/riatlimited',
        igRoute: 'https://www.instagram.com/riatbrasil/',
      },
    },
  ];

  const BackgroundPoints = {
    position: 'absolute',
    top: '260px',
    right: '0',
  };

  const BackgroundPoints2 = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: -1,
  };

  const rellaxRef = useRef();

  useEffect(() => {
    new Rellax(rellaxRef.current, {
      speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);

  const rellaxPointsRef = useRef();

  useEffect(() => {
    new Rellax(rellaxPointsRef.current, {
      speed: -4,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);

  const companyCluster = [];

  for (let i = 0; i < companies.length; i += 5) {
    const chunk = companies.slice(i, i + 5);
    companyCluster.push(chunk);
  }

  return (
    <MKBox
      width="100%"
      py={4}
      sx={{
        position: 'relative',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CustomContainer>
        <MKBox pt={8} pb={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Only some of our <b>Highlights</b>
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>

        {companyCluster.map((chunk, idx) => (
          <Grid
            key={idx}
            container
            spacing={1}
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
            py={{ xs: 0, sm: 6 }}
          >
            {chunk.map((company, innerIdx) => (
              <Grid item xs={12} lg={2}>
                <StartUpBlogCard {...company} key={innerIdx} />
              </Grid>
            ))}
          </Grid>
        ))}
        <MKBox
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <MKButton
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => {
              analyticsTag({
                event: 'purchase',
              });
              window.location.href = '/portfolio';
            }}
          >
            See all
          </MKButton>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Companies;
