import Grid from '@mui/material/Grid';
import React from 'react';
import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';
import { analyticsTag } from 'helpers/coreHelper';

// Images Header
import fishBlue from 'assets/images/azul.gif';
import fishOrange from 'assets/images/naranja.gif';

const blueFishStyle = {
  width: '130px',
  position: 'absolute',
  animation: 'moveFishBlue 10s linear infinite',
};

const orangeFishStyle = {
  width: '130px',
  position: 'absolute',
  animation: 'moveFishOrange 10s linear infinite',
};

const keyframes = `
@keyframes moveFishOrange {
  0% {
    transform: translateX(60vw);
  }
  100% {
    transform: translateX(-60vw);
  }
}

@keyframes moveFishBlue {
  0% {
    transform: translateX(-60vw);
  }
  100% {
    transform: translateX(60vw);
  }
}
`;

function Banner() {
  const buttonStyle = {
    position: 'relative',
  };

  return (
    <>
      <style>{keyframes}</style>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img src={fishBlue} alt="fish" style={{ ...blueFishStyle, bottom: '10%' }} />
        <img src={fishOrange} alt="fish" style={{ ...orangeFishStyle, top: '10%' }} />
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
          <Grid
            container
            spacing={1}
            sx={{
              my: { xs: 25, sm: 15 },
              px: { xs: 1, lg: 0 },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid item xs={12} lg={8} flexDirection="column" sx={buttonStyle} id="fish">
              <MKTypography
                variant="h1"
                color="text"
                sx={{ textAlign: 'center', FontWeight: '650px', fontSize: '3rem' }}
              >
                <span style={{ color: '#DB6639', textDecoration: 'underline' }}>Guiding</span> the
                next generation of impact driven{' '}
                <span style={{ color: '#5D5DFB', textDecoration: 'underline' }}>startups.</span>
              </MKTypography>
            </Grid>
            <MKBox
              width="100%"
              display={{ xs: 'flex', lg: 'none' }}
              justifyContent="center"
              alignItems="center"
              mt={8}
            >
              <MKButton
                color="secondary"
                size="large"
                sx={{}}
                onClick={() => {
                  analyticsTag({
                    event: 'purchase',
                  });
                  window.open(
                    'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                    '_blank'
                  );
                }}
              >
                Apply Now
              </MKButton>
            </MKBox>
          </Grid>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Banner;
