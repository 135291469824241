// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

function CardDescription() {
  const logosMap = [
    {
      name: 'Double selection process',
    },
    {
      name: 'Exhaustive due dilligence',
    },
    {
      name: 'Positive impact',
    },
    {
      name: 'Diversified',
    },
    {
      name: 'Investment and Startup tracking platform',
    },
    {
      name: 'High expected returns in a shorter time',
    },
  ];
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    padding: '16px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  return (
    <MKBox display="flex" position="relative">
      <CustomContainer>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
          py={5}
        >
          {logosMap &&
            logosMap.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {}}
              >
                <MKTypography variant="h6" color="text" mb={1}>
                  {item.name}
                </MKTypography>
              </MKBox>
            ))}
        </MKBox>
        <MKTypography
          color="text"
          variant="h5"
          px={{
            xs: 0,
            md: 5,
          }}
          sx={{
            fontWeight: 300,
            width: { xs: '100%', md: '100%' },
          }}
        >
          Our rolling fund is meticulously designed to create <b>positive change</b> in the
          ecosystem while providing investors with the potential for substantial{' '}
          <b>financial growth</b>. By focusing on both social responsibility and robust financial
          performance, we ensure that your investment not only yields impressive returns but also
          contributes to <b>meaningful and lasting improvements</b> in the communities and
          industries we support.
        </MKTypography>
        <MKBox pt={6} mt={10} textAlign="center">
          <MKTypography
            variant="flywheel"
            color="primary"
            sx={{ fontWeight: 1000, fontSize: '2rem' }}
          >
            · Our Track Record
          </MKTypography>
          <Grid container spacing={1} justifyContent="space-around" mt={10} alignItems={'center'}>
            <Grid sx={{ display: 'flex', flexDirection: { xs: 'column' }, alignItems: 'start' }}>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: '33%' },
                  mt: { xs: 10, sm: 0 },
                }}
              >
                <MKTypography
                  variant="h1"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: '#DB6639', fontSize: 60 }}
                >
                  2x
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  Average return
                </MKTypography>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },

                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: 'auto' },
                  mt: { xs: 10, sm: 10 },
                }}
              >
                <MKTypography
                  variant="h1"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: '#DB6639', fontSize: 60 }}
                >
                  3
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  SPV
                </MKTypography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column' },
                alignItems: 'center',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },

                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: 'auto' },
                  mt: { xs: 10, sm: 0 },
                }}
              >
                <MKTypography
                  variant="h1"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: '#DB6639', fontSize: 60 }}
                >
                  2.3M
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  USD AUM
                </MKTypography>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },

                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: 'auto' },
                  mt: { xs: 10, sm: 10 },
                }}
              >
                <MKTypography
                  variant="h1"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: '#DB6639', fontSize: 60 }}
                >
                  +400k
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  Raised to date
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default CardDescription;
