import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import { analyticsTag } from 'helpers/coreHelper';
import MKButton from 'DS/components/MKButton';

// Images Header
import fishDown from 'assets/images/fish_down.png';
import fishUp from 'assets/images/fish_up.png';
import { Icon } from '@mui/material';

// Images Avatars

function Banner({}) {
  const messageWpp = 'Hola Koi, me interesa conocer más sobre las oportunidades de inversión';
  const buttonStyle = {
    position: 'relative',
    '&:before': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: { sx: '0%', lg: '0%' },
      right: { sx: '0%', lg: '0%' },
      width: '54px',
      height: '136px',
      zIndex: '-1',
      transform: 'rotate(180deg)',
    },
  };
  return (
    <MKBox
      width="100%"
      sx={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        <Grid container spacing={1} sx={{ mt: { xs: '20px', sm: '60px' }, px: { xs: 1, lg: 5 } }}>
          <Grid
            item
            xs={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: { xs: 0, lg: 2 },
            }}
          >
            <Grid item xs={12} lg={12} sx={{ textAlign: 'start' }}>
              <MKTypography variant="h5" color="primary" sx={{ fontWeight: 300 }}>
                Investment thesis
              </MKTypography>

              <MKTypography
                variant="h1"
                color="text"
                fontWeight="light"
                sx={{ my: { xs: '20px', sm: '30px' } }}
              >
                We offer <b>groundbreaking investment opportunities</b>, empowering the ecosystem to
                thrive and <b>innovate</b>.
                <MKTypography
                  variant="body1"
                  sx={{ fontWeight: 300, width: { sx: '100%', md: '50%' }, mt: 1, color: 'grey' }}
                >
                  If you're looking for a high-impact investment opportunity, you're in the right
                  place. Talk to us and learn more about our investment thesis.
                </MKTypography>
              </MKTypography>

              <MKBox ml={0}>
                <MKButton
                  color="black"
                  size="large"
                  location="external"
                  onClick={() => {
                    analyticsTag({
                      event: 'purchase',
                    });
                    window.location = 'https://wa.me/+5491163356738?text=' + messageWpp;
                  }}
                >
                  Let's talk
                  <Icon fontSize="small" sx={{ ml: 2 }}>
                    send
                  </Icon>
                </MKButton>
              </MKBox>
              <Grid
                item
                xs={12}
                lg={12}
                pt={15}
                alignItems="flex-start"
                flexDirection="column"
                sx={buttonStyle}
              >
                <MKTypography variant="flywheel" color="primary">
                  · HOW WE DO IT
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Banner;
