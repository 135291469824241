// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import MKAvatar from 'DS/components/MKAvatar';

import linkedinImg from 'assets/images/linkedin_black.png';

function TestimonialCard({
  title,
  subtitle,
  quote,
  twitter,
  direction,
  img,
  color,
  small,
  ...rest
}) {
  const Quote = {
    '&::before': {
      content: 'open-quote',
    },
    '&::after': {
      content: 'close-quote',
    },
  };

  const Twitter = {
    '&::before': {
      content: '""',
      backgroundImage: `url(${linkedinImg})`,
      width: '22px',
      height: '17px',
      display: 'block',
      position: 'relative',
      float: 'left',
      margin: '0 10px 0 0',
    },
  };

  return (
    <MKBox
      lineHeight={1}
      p={direction === 'center' ? 2 : 0}
      sx={({ palette: { gradients } }) => ({
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        border: '1px solid',
        borderColor: gradients[color] && gradients[color].main,
        padding: '40px',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 150ms ease-in',
        height: 'auto',
        justifyContent: 'space-between',
        '&:hover': {
          boxShadow: `-6px 6px 0px 0px ${gradients[color] && gradients[color].main}`,
          marginTop: '-6px',
          marginLeft: '6px',
          marginRight: '-6px',
        },
      })}
      {...rest}
    >
      <MKTypography
        display="block"
        variant={'quote'}
        sx={{
          ...Quote,
        }}
      >
        {quote}
      </MKTypography>

      <Grid
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        display="flex"
      >
        <MKBox display="flex" alignItems="start" flexDirection="column">
          {/* <MKTypography
            display="block"
            variant={'twitter'}
            sx={{
              ...Twitter,
            }}
          >
            {twitter}
          </MKTypography> */}
          <MKBox display="flex" alignItems="start" flexDirection="row" gap="8px">
            <MKTypography display="block" variant="name" fontWeight="bold">
              {title}
            </MKTypography>
            <MKTypography display="block" variant="name">
              {subtitle}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKAvatar src={img} alt="team 1" size="sm" />
      </Grid>
    </MKBox>
  );
}

// Setting default props for the TestimonialCard
TestimonialCard.defaultProps = {
  color: 'info',
  direction: 'left',
  small: false,
};

// Typechecking props for the TestimonialCard
TestimonialCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  small: PropTypes.bool,
};

export default TestimonialCard;
